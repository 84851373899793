import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, minWidth, units } from '@calm-web/design-system';

export const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: ${units(4)};
	img {
		width: ${units(16)};
	}
`;

export const Title = styled.div`
	margin-top: ${units(4)};
	text-align: center;
	${fontSize(FontSizes['2xl'])};
`;

export const Subtitle = styled.div`
	padding: ${units(1)};
	${fontWeight(FontWeights.Regular)};
	text-align: center;
	${fontSize(FontSizes.sm)};
	color: ${palette('gray5')};
`;

export const Updated = styled.div`
	margin-top: ${units(2)};
	padding: ${units(1)};
	${fontSize(FontSizes.sm)};
	color: ${palette('gray5')};
	${fontWeight(FontWeights.Light)};
	border-radius: 3px;
`;

export const ContentWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const Content = styled.div`
	padding: ${units(8)};
	max-width: ${units(120)};
`;

export const CenteredContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: ${units(2)};
`;

export const SplitContent = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: ${units(4)};
	margin-bottom: ${units(6)};
	${minWidth('tablet')} {
		flex-direction: row;
	}
`;

export const SectionHeader = styled.h4`
	margin-bottom: ${units(1)};
	${fontSize(FontSizes.lg)};
`;

export const SectionBody = styled.div`
	padding: ${units(1)};
	margin-bottom: ${units(5)};
	border-bottom: 1px solid ${palette('gray3')};
	p {
		${fontWeight(FontWeights.Light)};
		margin-bottom: ${units(2)};
	}
	ul,
	ol {
		margin-bottom: ${units(2)};
	}
	a {
		color: ${palette('blue3')};
	}
	img {
		padding-bottom: ${units(4)};
		max-width: 100%;
	}
	table td {
		padding: 10px;
		border: 1px solid ${palette('gray3')};
	}
	table th {
		text-align: left;
		${fontWeight(FontWeights.Medium)};
		padding: 10px;
		background-color: ${palette('gray1')};
	}
	code {
		${fontWeight(FontWeights.Demi)};
		color: ${palette('gray7')};
	}

	ol ol {
		list-style-type: lower-alpha;
	}
`;

export const EligibilityFlexWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const PaddedListItem = styled.li`
	padding-bottom: ${units(2)};
`;

export const TroubleDescription = styled.div`
	padding-left: ${units(5)};
`;

export const ApiSectionHeader = styled.div`
	${fontWeight(FontWeights.Medium)};
	border-bottom: 1px solid ${palette('gray3')};
	margin-bottom: ${units(2)};
`;

export const ApiCode = styled.div`
	color: ${palette('gray7')};
	padding: ${units(3)};
	margin-bottom: ${units(3)};
	border-radius: ${units(1)};
	background-color: rgba(59, 190, 236, 0.3);
	overflow-wrap: break-word;
`;

export const ApiCodeHeader = styled.div`
	color: ${palette('blue3')};
	${fontWeight(FontWeights.Medium)};
	margin-bottom: ${units(2)};
`;

export const ApiCodeResponse = styled.div`
	color: ${palette('gray5')};
	${fontWeight(FontWeights.Medium)};
	margin-bottom: ${units(2)};
`;

export const ApiResponseBlob = styled.div`
	color: rgba(0, 131, 0, 1);
	display: flex;
	flex-direction: column;
	padding-left: ${units(3)};
`;

export const ApiURL = styled.div`
	display: inline;
	color: ${palette('errorRed')};
`;

export const LandingImg = styled.img`
	width: 100%;
`;

export const SamlImg = styled.img`
	width: 100%;
`;

export const ApiImg = styled.img`
	width: 100%;
`;

export const FaqQuestion = styled.div`
	margin-top: ${units(1)};
	${fontWeight(FontWeights.Medium)};
`;

export const FaqAnswer = styled.div`
	color: ${palette('gray6')};
	padding: ${units(1)} ${units(2)} ${units(4)} ${units(2)};
`;

export const CodeBlock = styled.code`
	overflow-wrap: break-word;
	display: block;
`;

export const InlineCode = styled.span`
	padding: 0.2em 0.4em;
	margin-bottom: ${units(0.3)};
	white-space: break-spaces;
	background-color: ${palette('gray1')};
	border-radius: 6px;
	display: inline-block;
	font-family: monospace;
	font-size: ${units(1.8)};
`;
